import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import root from 'window-or-global';

export default function ShopifyBuy({ className }) {
  useEffect(() => {
    (function init() {
      const scriptURL = 'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js';

      function ShopifyBuyInit() {
        const client = root.ShopifyBuy.buildClient({
          domain: 'visionposter.myshopify.com',
          storefrontAccessToken: '2d787fe1c87ca62f49422195b9c487b2',
        });
        root.ShopifyBuy.UI.onReady(client).then((ui) => {
          ui.createComponent('product', {
            id: '4512205635717',
            node: root.document.getElementById('product-component-1580812224557'),
            moneyFormat: '%24%7B%7Bamount%7D%7D',
            options: {
              product: {
                styles: {
                  product: {
                    '@media (min-width: 601px)': {
                      'max-width': 'calc(25% - 20px)',
                      'margin-left': '20px',
                      'margin-bottom': '50px',
                    },
                  },
                  button: {
                    'font-family': 'Roboto, sans-serif',
                    'font-weight': 'bold',
                    'font-size': '16px',
                    'padding-top': '16px',
                    'padding-bottom': '16px',
                    ':hover': {
                      'background-color': '#e60000',
                    },
                    'background-color': '#ff0000',
                    ':focus': {
                      'background-color': '#e60000',
                    },
                    'border-radius': '0px',
                    'padding-left': '36px',
                    'padding-right': '36px',
                  },
                  quantityInput: {
                    'font-size': '16px',
                    'padding-top': '16px',
                    'padding-bottom': '16px',
                  },
                },
                contents: {
                  img: false,
                  title: false,
                  price: false,
                },
                text: {
                  button: 'Add to cart',
                },
                googleFonts: ['Roboto'],
              },
              productSet: {
                styles: {
                  products: {
                    '@media (min-width: 601px)': {
                      'margin-left': '-20px',
                    },
                  },
                },
              },
              modalProduct: {
                contents: {
                  img: false,
                  imgWithCarousel: true,
                  button: false,
                  buttonWithQuantity: true,
                },
                styles: {
                  product: {
                    '@media (min-width: 601px)': {
                      'max-width': '100%',
                      'margin-left': '0px',
                      'margin-bottom': '0px',
                    },
                  },
                  button: {
                    'font-family': 'Roboto, sans-serif',
                    'font-weight': 'bold',
                    'font-size': '16px',
                    'padding-top': '16px',
                    'padding-bottom': '16px',
                    ':hover': {
                      'background-color': '#e60000',
                    },
                    'background-color': '#ff0000',
                    ':focus': {
                      'background-color': '#e60000',
                    },
                    'border-radius': '0px',
                    'padding-left': '36px',
                    'padding-right': '36px',
                  },
                  quantityInput: {
                    'font-size': '16px',
                    'padding-top': '16px',
                    'padding-bottom': '16px',
                  },
                },
                googleFonts: ['Roboto'],
                text: {
                  button: 'Add to cart',
                },
              },
              cart: {
                styles: {
                  button: {
                    'font-family': 'Roboto, sans-serif',
                    'font-weight': 'bold',
                    'font-size': '16px',
                    'padding-top': '16px',
                    'padding-bottom': '16px',
                    ':hover': {
                      'background-color': '#e60000',
                    },
                    'background-color': '#ff0000',
                    ':focus': {
                      'background-color': '#e60000',
                    },
                    'border-radius': '0px',
                  },
                },
                text: {
                  total: 'Subtotal',
                  button: 'Checkout',
                },
                googleFonts: ['Roboto'],
              },
              toggle: {
                styles: {
                  toggle: {
                    'font-family': 'Roboto, sans-serif',
                    'font-weight': 'bold',
                    'background-color': '#ff0000',
                    ':hover': {
                      'background-color': '#e60000',
                    },
                    ':focus': {
                      'background-color': '#e60000',
                    },
                  },
                  count: {
                    'font-size': '16px',
                  },
                },
                googleFonts: ['Roboto'],
              },
            },
          });
        });
      }

      function loadScript() {
        const script = root.document.createElement('script');
        script.async = true;
        script.src = scriptURL;
        (
          root.document.getElementsByTagName('head')[0]
          || root.document.getElementsByTagName('body')[0]
        ).appendChild(script);
        script.onload = ShopifyBuyInit;
      }

      if (root.ShopifyBuy) {
        if (root.ShopifyBuy.UI) {
          ShopifyBuyInit();
        } else {
          loadScript();
        }
      } else {
        loadScript();
      }
    }());
  }, []);

  return <div className={className} id="product-component-1580812224557" />;
}

ShopifyBuy.propTypes = {
  className: PropTypes.string,
};

ShopifyBuy.defaultProps = {
  className: '',
};
