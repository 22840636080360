import React from 'react';
import styled from 'styled-components';

const EmbedContainer = styled.div`
  position: relative;
  max-width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
  & iframe,
  & object,
  & embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export default function YouTubeEmbed() {
  return (
    <EmbedContainer>
      <iframe
        title="Vision Poster Demo"
        src="https://www.youtube.com/embed/0KDUsNLjuzc?autoplay=1&loop=1&playlist=0KDUsNLjuzc&modestbranding=1&controls=0&showinfo=0&playsinline=1"
        frameBorder="0"
        allowFullScreen
      />
    </EmbedContainer>
  );
}
