import React from 'react';

export default function ValueProps() {
  return (
    <ul>
      <li>Planning your dreaded school routines.</li>
      <li>Setting work deadlines you&apos;ll never meet.</li>
      <li>Marking life milestones you wish you accomplished.</li>
      <li>Plotting world domination.</li>
      <li>The possibilities are endless!</li>
    </ul>
  );
}
