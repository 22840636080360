import React from 'react';
import styled from 'styled-components';
import ImageMarkers from './image-markers';

const Link = styled.a`
  display: block;
  max-width: 120px;
  border: 3px solid transparent;
  &:hover {
    border: 3px solid red;
  }
`;

export default function MarkerRecommendation() {
  return (
    <>
      <Link href="https://amzn.to/38ArqEA" target="_blank">
        <ImageMarkers />
      </Link>
      <p>
        Markers to etch in&nbsp;history.
        {' '}
        <span role="img" aria-label="Marker">✍️</span>
      </p>
    </>
  );
}
