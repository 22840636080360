import React from 'react';
import styled from 'styled-components';
import ImageCommandStrips from './image-command-strips';

const Link = styled.a`
  display: block;
  max-width: 120px;
  border: 3px solid transparent;
  &:hover {
    border: 3px solid red;
  }
`;

export default function MountRecommendation() {
  return (
    <>
      <Link href="https://amzn.to/2OW6aBI" target="_blank">
        <ImageCommandStrips />
      </Link>
      <p>
        Command sticker mounts to hang your&nbsp;poster.
        {' '}
        <span role="img" aria-label="Picture Frame">🖼</span>
      </p>
    </>
  );
}
