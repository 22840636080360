import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import ImageCalendar from '../components/image-calendar';
import SEO from '../components/seo';
import ShopifyBuy from '../components/shopify-buy';
import ValueProps from '../components/value-props';
import YouTubeEmbed from '../components/youtube-embed';
import MarkerRecommendation from '../components/marker-recommendation';
import MountRecommendation from '../components/mount-recommendation';

const Content = styled.div`
  margin: 0 0 24px;
  padding: 0 24px;
`;
const Price = styled.div`
  text-align: center;
`;
const Header = styled.h2`
  text-transform: uppercase;
  font-size: 16px;
  &::before {
    content: "# ";
  }
`;
const Caption = styled.p`
  text-align: center;
`;
const Hero = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;
const ShopifyBuyButton = styled(ShopifyBuy)`
  position: sticky;
  top: 0;
  margin: 0 auto;
  z-index: 9000;
`;

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Content>
      <Price>$30 + Tax + Shipping + Heart + Soul</Price>
      <ShopifyBuyButton />
      <Hero>
        <ImageCalendar />
        <Caption>
          18&Prime; &times; 24&Prime;
          {' '}
          <span role="img" aria-label="Banana">🍌</span>
          for scale.
        </Caption>
        <Header>
          What&apos;s this even for?
          {' '}
          <span role="img" aria-label="Man Shrugging">🤷‍♂️</span>
        </Header>
        <ValueProps />
        <Header>
          Demo Time
          {' '}
          <span role="img" aria-label="Dancing Man">
            🕺
          </span>
        </Header>
        <YouTubeEmbed />
        <Caption>
          Use a thin wet erase marker for best&nbsp;results!&nbsp;
          {' '}
          <span role="img" aria-label="Water">💦</span>
        </Caption>
        <Header>
          Recommendations
          {' '}
          <span role="img" aria-label="Tools">🛠</span>
        </Header>
        <p>I included Amazon affiliate links below to get you started and get me&nbsp;paid.</p>
        <ul>
          <li><MarkerRecommendation /></li>
          <li><MountRecommendation /></li>
        </ul>
      </Hero>
    </Content>
  </Layout>
);

export default IndexPage;
